import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {FaEnvelope, FaPhone, FaMapMarkerAlt, FaTwitter} from 'react-icons/fa'
import logo from '../images/shepperd-fox-logo-black.svg'

const Footer = () => {
    const data = useStaticQuery(graphql`
    query {
      contact: markdownRemark (frontmatter: {templateKey: {eq: "contact-page"}}) {
        frontmatter {
          title
          subtitle
          contact_email
          contact_phone
          contact_address
          map
        }
        html
      }
    }
  `)
    
    return (
    <footer className="section">
        <div className="footer-info columns">
          <div className="column is-quarter is-flex">
            <FaEnvelope />
            <div>
              <h2>EMAIL</h2>
              <p><a href={`mailto:${data.contact.frontmatter.contact_email}`}>{data.contact.frontmatter.contact_email}</a></p>
            </div>
          </div>

          <div className="column is-quarter is-flex">
            <FaPhone />
            <div>
              <h2>CONTACT THE OFFICE</h2>
              <p><a href={`tel:${data.contact.frontmatter.contact_phone}`}>{data.contact.frontmatter.contact_phone}</a></p>
            </div>
          </div>

          <div className="column is-quarter is-flex">
            <FaMapMarkerAlt />
            <div>
              <h2>WHERE ARE WE?</h2>
              <p>
              {
                //nl2br react replacement https://medium.com/@kevinsimper/react-newline-to-break-nl2br-a1c240ba746
                data.contact.frontmatter.contact_address.split('\n').map((item, key) => {
                  return <span key={key}>{item}<br/></span>
                })
              }
              </p>
            </div>
          </div>

          <div className="column is-quarter is-flex">
            <FaTwitter className="twitter"/>
            <div>
              <h2>FOLLOW US</h2>
              <p>Follow us on Twitter
              to get live updates 
              <a href="https://twitter.com/@shepperdfox">@shepperdfox</a></p>
            </div>
          </div>
        </div>  
        <div className="footer-credits">
            <small>© Shepperd Fox {new Date().getFullYear()}.</small> 
            <img src={logo} alt="Shepperd Fox Logo"/>
            <small>Website by <a href="https://exposuredesign.co.uk">Exposure Design</a></small>
        </div>    
      </footer>
    )
}

export default Footer