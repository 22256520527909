import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import logo from '../images/shepperd-fox-logo-blue.svg'


const Header = ({ siteTitle }) => {
  
  const [ navOpen, setNavOpen] = useState(false)

  const toggleNavBar = () => {
    console.log('burger clicked')
    const navBarBurger = document.querySelector(".navbar-burger")
    const navBarMenu = document.querySelector(".navbar-menu")
    if ( navOpen ) {
      navBarMenu.classList.remove("is-active")
      navBarBurger.classList.remove("is-active") 
      setNavOpen(false)
    } else {
      navBarMenu.classList.add("is-active")
      navBarBurger.classList.add("is-active") 
      setNavOpen(true)
    }
  }
  return (
  <header className={`container`}>
    
    

    <nav className="navbar" role="navigation" aria-label="main navigation">
    <div className="navbar-brand">
      
        <Link className="navbar-item" to="/"> 
          <img src={logo}  alt={siteTitle} />
        </Link>
      
  
      <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={toggleNavBar}>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
  
    <div id="navbar" className="navbar-menu">
      <div className="navbar-start">
      
      </div>
          
      <div className="navbar-end">
      <Link className="navbar-item" activeClassName="is-active" to="/">Home</Link>
      <Link className="navbar-item" partiallyActive={true} activeClassName="is-active" to="/clients">Clients</Link>
      <Link className="navbar-item" partiallyActive={true} activeClassName="is-active" to="/news">News</Link>
      <Link className="navbar-item" activeClassName="is-active" to="/team">Team</Link>
      <Link className="navbar-item" activeClassName="is-active" to="/contact">Contact</Link>
      </div>
    </div>
  </nav>

  </header>
)

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
